import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { FeatureFlags } from "context/FeatureFlags";
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import theme from "./assets/theme";
import LogConsole from "./components/logConsole";
import brandSendPad from "./assets/images/sendpad/sendpad-login-logo.32c527ca.svg";
import brandMailsense from "./assets/images/mailsenselogo.png";
import brandArroyomailer from "./assets/arroyo_media_logo.png";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

/* eslint-disable */
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const isInitiallyLogged =
    localStorage.getItem("AuthorizationToken") !== null &&
    localStorage.getItem("AuthorizationToken") !== "undefined";
  const { features } = useContext(FeatureFlags);
  // websocket "wss://io.mailsense.ai/hub/"
  // process.env.REACT_APP_WEBSOCKET_BASE_URL ? process.env.REACT_APP_WEBSOCKET_BASE_URL : ""

  ////////////////////////////////////////

  theme.palette.background.default = features.colorBackground;
  theme.palette.primary.main = features.colorPrimary;
  theme.palette.primary.focus = features.colorPrimaryFocus;
  theme.palette.gradients.primary.main = features.colorPrimaryGradient;
  theme.palette.gradients.primary.state = features.colorPrimaryGradientState;

  const getBrand = () => {
    switch (features.plName) {
      case "sendpad":
        return brandSendPad;
      case "mailsense":
        return brandMailsense;
      case "arroyomailer":
        return brandArroyomailer;
      default:
        return brandSendPad;
    }
  };

  const getBrandName = () => {
    switch (features.plName) {
      case "sendpad":
        return "";
      case "mailsense":
        return (
          <MDBox display="flex">
            <MDTypography variant="h6" color="text">
              Mail
            </MDTypography>
            <MDTypography variant="h6" color="primary">
              Sense
            </MDTypography>
          </MDBox>
        );
      case "arroyomailer":
        return (
          <MDBox display="flex">
            <MDTypography variant="h6" color="">
              Arroyo
            </MDTypography>
            <MDTypography variant="h6" color="primary">
              Mailer
            </MDTypography>
          </MDBox>
        );
      default:
        return "";
    }
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      let returnRoute = "";
      if (route.route) {
        if (
          route.type === "internal" ||
          route.type === "route" ||
          route.route === "/thank-you" ||
          route.route === "/already-subscribed" ||
          route.route === "/authentication/sign-in" ||
          route.route === "/authentication/sign-up" ||
          route.route === "/"
        )
          /*route.route === "/done" ||
          route.route === "/subscription" ||
          route.route === "/createjson" ||
          */
          returnRoute = (
            <Route exact path={route.route} element={route.component} key={route.key} />
          );
        else
          returnRoute = isInitiallyLogged ? (
            <Route exact path={route.route} element={route.component} key={route.key} />
          ) : (
            ""
          );
        return returnRoute;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {isInitiallyLogged &&
        pathname != "/authentication/sign-in" &&
        pathname != "/authentication/sign-up" &&
        pathname != "/done" &&
        pathname != "/grape" &&
        pathname != "/broadcasts/preview" &&
        pathname != "/subscription" &&
        pathname != "/forgot" &&
        pathname != "/forgotDone" &&
        pathname != "/thank-you" &&
        pathname != "/confirm-email" &&
        pathname != "/passwordreset" &&
        pathname != "/already-subscribed" &&
        pathname != "/" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={getBrand()}
              brandName={getBrandName()}
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </>
        )}

      <Routes>
        {getRoutes(routes)}
        {isInitiallyLogged ? (
          <Route path="/reports" element={<Navigate to="/reports" />} />
        ) : (
          <Route
            path="/authentication/sign-in"
            element={<Navigate to="/authentication/sign-in" />}
          />
        )}
      </Routes>

      {isInitiallyLogged &&
        pathname != "/authentication/sign-in" &&
        pathname != "/authentication/sign-up" &&
        pathname != "/broadcasts/preview" &&
        pathname != "/done" &&
        pathname != "/grape" &&
        pathname != "/subscription" &&
        pathname != "/forgot" &&
        pathname != "/forgotDone" &&
        pathname != "/thank-you" &&
        pathname != "/passwordreset" &&
        pathname != "/already-subscribed" &&
        pathname != "/confirm-email" &&
        pathname != "/" && <LogConsole />}
    </ThemeProvider>
  );
}
