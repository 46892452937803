import { useEffect, useRef, useState } from "react";
// react-router-dom components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import csc from "country-state-city";
import { FormLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { createFilterOptions } from "@mui/base";

/* eslint-disable */
function Edit(props) {
  const [errorSB, setErrorSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Select a country");
  const [state, setState] = useState("Select a state");
  const [city, setCity] = useState("Select a city");
  const [zip, setZip] = useState("");
  const [education, setEducation] = useState("");
  const [children, setChildren] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [employment, setEmployment] = useState("");
  const [tags, setTags] = useState([]);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const [subscriber, setSubscriber] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const { openModal, closeModal } = props;
  const closeErrorSB = () => setErrorSB(false);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const countries = csc.getAllCountries();
  const filter = createFilterOptions();

  const clearVariables = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setCountry("Select a country");
    setState("Select a state");
    setCity("Select a city");
    setZip("");
    setEducation("");
    setChildren("");
    setEthnicity("");
    setEmployment("");
    setTags([]);

    simpleValidator.current.hideMessages();
    props.onClose();
  };

  const handleSave = () => {
    if (simpleValidator.current.allValid()) {
      setIsLoading(true);
      const options = {
        method: "PUT",
        url: `subscribers`,
        headers: {
          "content-type": "application/json",
        },
      };

      options.data = JSON.stringify({
        sub_id: props.subId,
        sub_email: email,
        sub_first: firstName,
        sub_last: lastName,
        sub_phone: phone,
        sub_country: country == "Select a country" ? "" : country,
        sub_state: state == "Select a state" ? "" : state,
        sub_city: city == "Select a city" ? "" : city,
        sub_zip: zip,
        sub_education: education,
        sub_children: children,
        sub_ethnicity: ethnicity,
        sub_employment: employment,
        sub_tags: tags.join(","),
        sub_source: subscriber.sub_source,
        sub_optinIP: subscriber.sub_optinIP,
        sub_gender: subscriber.sub_gender,
        sub_dateOfBirth: subscriber.sub_dateOfBirth,
        sub_optinDate: subscriber.sub_optinDate,
        sub_lastEngagementDate: subscriber.sub_lastEngagementDate,
        sub_status: subscriber.sub_status,
        sub_swe: subscriber.sub_swe,
        sub_emc_id: subscriber.sub_emc_id,
        sub_per_id: subscriber.sub_per_id,
        customFields: customFields,
      });

      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          props.onDone();
          clearVariables();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getById = () => {
    setIsLoading(true);
    const options = {
      method: "GET",
      url: `subscribers/getById?sub_id=${props.subId}`,
      headers: {
        "content-type": "application/json",
      },
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setSubscriber(response);
        setEmail(response.sub_email);
        setFirstName(response.sub_first);
        setLastName(response.sub_last);
        setPhone(response.sub_phone);
        setZip(response.sub_zip);
        setEducation(response.sub_education);
        setChildren(response.sub_children);
        setEthnicity(response.sub_ethnicity);
        setEmployment(response.sub_employment);
        response.sub_tags == "" ? setTags([]) : setTags(response.sub_tags.split(","));
        setCustomFields(response.customFields);
        // console.log(response.sub_tags);
        /*
        if (response.sub_country != "" && countries.length > 0) {
          setStates(
            csc.getStatesOfCountry(countries.filter((c) => c.name == response.sub_country)[0].id)
          );

          if (response.sub_state != "" && states.length > 0)
            setCities(
              csc.getCitiesOfState(states.filter((s) => s.name == response.sub_state)[0].id)
            );
        }
*/
        setCity(response.sub_city);
        setState(response.sub_state);
        setCountry(response.sub_country);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTags();
  }, []);

  const changeCustomField = (value, index) => {
    const newCustomFields = customFields.map((field, i) => {
      if (i === index) {
        return {
          scf_id: field.scf_id,
          scf_sub_id: field.scf_sub_id,
          scf_name: field.scf_name,
          scf_value: value,
        };
      } else {
        return field;
      }
    });
    setCustomFields(newCustomFields);
  };

  /*
  useEffect(() => {
    if (country.trim() != "Select a country" && countries.length > 0) {
      setStates(csc.getStatesOfCountry(countries.filter((c) => c.name == country)[0].id));
    } else setState("Select a state");
  }, [country]);

  useEffect(() => {
    if (state.trim() != "Select a state" && states.length > 0) {
      const ci = csc.getCitiesOfState(states.filter((s) => s.name == state));
      if (ci.length > 0) setCities(ci[0].id);
    } else setCity("Select a city");
  }, [state]);
*/
  useEffect(() => {
    if (props.subId > 0) {
      getById();
    }
  }, [props.subId]);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Connection failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <Dialog
      open={openModal}
      onClose={clearVariables}
      aria-labelledby="responsive-dialog-title"
      fullWidth
    >
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="xs"
        coloredShadow="primary"
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Edit subscriber
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pb={3} px={2}>
          <MDBox component="form" role="form">
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBox color="red">
                {simpleValidator.current.message("Email", email, "required|email")}
              </MDBox>
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <MDBox color="red">{simpleValidator.current.message("Phone", phone, "phone")}</MDBox>
            </MDBox>
            {customFields.map((field, index) => (
              <MDBox m={2} key={index}>
                <MDInput
                  fullWidth
                  type="text"
                  label={field.scf_name}
                  value={field.scf_value}
                  onChange={(e) => changeCustomField(e.target.value, index)}
                />
              </MDBox>
            ))}
            <MDBox m={2}>
              <Autocomplete
                value={tags}
                onChange={(event, newValue) => {
                  setTags(
                    newValue.map((tag) =>
                      tag.indexOf('Create new tag "') === -1 ? tag : tag.substring(16).slice(0, -1)
                    )
                  );
                }}
                multiple
                id="tags-filled"
                options={suggestionsTag.map((option) => option.name)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Type to search or add a new tag and press enter"
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option);
                  if (inputValue.trim() !== "" && !isExisting) {
                    filtered.push(`Create new tag "${inputValue}"`);
                  }
                  return filtered;
                }}
              />
            </MDBox>
            <MDBox m={2}>
              <MDInput fullWidth type="text" label="Country" value={country} />
            </MDBox>
            <MDBox m={2}>
              <MDInput fullWidth type="text" label="State" value={state} />
            </MDBox>
            <MDBox m={2}>
              <MDInput fullWidth type="text" label="City" value={city} />
            </MDBox>
            <MDBox m={2}>
              <MDInput
                fullWidth
                type="text"
                label="Zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isLoading}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </MDButton>
        <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
          Save
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDButton>
      </DialogActions>

      {renderErrorSB}
    </Dialog>
  );
}
export default Edit;
