import GrapeEditor from "../grape/grapeEditor";

import client from "ApiClient";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function GrapeMigration() {
  const [jsonBody, setJsonBody] = useState(null);
  const [htmlBody, setHtmlBody] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const options = {
    method: "GET",
    url: "emailCopy",
  };

  var emcID = useRef(id);

  const getData = async () => {
    options.method = "GET";
    options.url = `emailCopy/getByIdNoAuth?emc_id=${searchParams.get("id")}`;
    await client
      .request(options)
      .then((response) => {
        emcID.current = response.emc_id;
        setResponseData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveHtml = (json, html) => {
    const options = {
      method: "PUT",
      url: `emailCopy/EditJsonGrape`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      emc_id: emcID.current,
      json: JSON.stringify(json),
      html: html,
    });
    console.log(options);
    client
      .request(options)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) getData();
  }, [id]);
  useEffect(() => {
    if (responseData) {
      setHtmlBody(responseData.emc_contentHTML);
    }
  }, [responseData]);

  return (
    <GrapeEditor
      onUpdate={(data, html) => {
        console.log(emcID);
        saveHtml(data, html, emcID);
      }}
      jsonData={jsonBody}
      htmlData={htmlBody}
    />
  );
}
