import { Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { MenuItem, Select } from "@mui/material";
import { width } from "@mui/system";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

/*eslint-disable*/
export default function MSAddCustomFields(props) {
  const [fields, setFields] = useState(props.fields);

  const handleNameChange = (index, event) => {
    let data = [...fields];
    data[index].name = event.target.value;
    setFields(data);
  };

  const handleChangeHeader = (index, event) => {
    let data = [...fields];
    data[index].mappedField = event.target.value;
    setFields(data);
  };

  const handleTypeChange = (index, event) => {
    const type = event.target.value;
    let data = [...fields];
    if (type === 2) {
      data[index].type = 2;
      data[index].groupable = true;
    } else {
      data[index].type = Number(type);
      data[index].groupable = false;
    }
    setFields(data);
  };

  const addField = () => {
    let newField = { name: "", mappedField: "", groupable: false, type: 0 };
    setFields([...fields, newField]);
  };

  const removeFields = (index) => {
    let data = [...fields];
    data.splice(index, 1);
    setFields(data);
  };

  useEffect(() => {
    props.setFields(fields);
  }, [fields]);

  useEffect(() => {
    if (props.fields.length != 0 && props.fields != fields) setFields(props.fields);
  }, [props.fields]);

  return (
    <div style={{ marginLeft: 15, marginRight: 15 }}>
      <Grid container spacing={2}>
        {fields &&
          fields.map((field, index) => {
            return (
              <Grid item xs={7}>
                <MDBox display="flex">
                  <Select
                    value={field.type}
                    label="Custom field type"
                    placeholder="Custom field type"
                    onChange={(event) => handleTypeChange(index, event)}
                    style={{ width: 180, marginRight: 10 }}
                  >
                    <MenuItem value={0}>Text</MenuItem>
                    <MenuItem value={1}>Date</MenuItem>
                    <MenuItem value={2}>Categorical</MenuItem>
                  </Select>
                  <MDInput
                    key={index}
                    label="Custom Field Name"
                    placeholder="Enter the name of the custom field"
                    value={field.name}
                    onChange={(event) => handleNameChange(index, event)}
                    style={{ width: 300, marginRight: 10 }}
                  />

                  <Select
                    value={field.mappedField}
                    onChange={(event) => handleChangeHeader(index, event)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ width: 300, marginRight: 10 }}
                  >
                    <MenuItem key={""} value={""}>
                      select the field to map
                    </MenuItem>
                    {props.mappedHeaders.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton
                    onClick={() => {
                      removeFields(index);
                    }}
                    sx={{ fontWeight: "bold" }}
                    color="error"
                    aria-label="prompt"
                  >
                    <Tooltip id="button-report" title="Remove custom field">
                      <Icon fontSize="small">delete</Icon>
                    </Tooltip>
                  </IconButton>
                </MDBox>
              </Grid>
            );
          })}
        <Grid item xs={5}>
          <MDBox>
            <Tooltip id="button-report" title="Add new custom field">
              <MDButton
                onClick={() => {
                  addField();
                }}
                color="primary"
                variant="contained"
                sx={{ width: 180 }}
              >
                Add Custom Field
              </MDButton>
            </Tooltip>
          </MDBox>
        </Grid>
      </Grid>
    </div>
  );
}
