import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import theme from "assets/theme";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Menu } from "@material-ui/core";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageIcon from "@material-ui/icons/Language";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import AddSubscriber from "./addSubscriber";
import Table from "./table";
import Add from "./add";

/* eslint-disable */
export default function Subscribers() {
  const [isLoading, setIsLoading] = useState(false);
  const [addGroup, setAddGroup] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [move, setMove] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [subscriberSeries, setSubscriberSeries] = useState({});
  const [subscriberSeriesData, setSubscriberSeriesData] = useState([]);
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSerie] = useState();
  const [currentSubscriber, setCurrentSubscriber] = useState({});
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseWebHook = () => setOpenWebHook(false);
  const [openWebHook, setOpenWebHook] = useState(false);
  const [importStatus, setImportStatus] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    method: "GET",
    url: "reports",
  };

  const handleAddGroup = () => {
    const refresh = addGroup + 1;
    setAddGroup(refresh);
  };

  const getSubscriberSeries = async () => {
    options.method = "GET";
    options.url = `reports/subscriberSeries`;

    await client
      .request(options)
      .then((response) => {
        setSubscriberSeries(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubscriberSeriesData = async () => {
    options.method = "GET";
    options.url = `subscribers/subscriberSeries`;
    await client
      .request(options)
      .then((response) => {
        setSubscriberSeriesData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getImportStatus();
    }, 60000); // 1 minute in milliseconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const getImportStatus = async () => {
    options.method = "GET";
    options.url = `subscribers/importStatus`;
    await client
      .request(options)
      .then((response) => {
        setImportStatus(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    localStorage.getItem("userId");
    getSubscriberSeries();
    getSubscriberSeriesData();
    getSeries();
  }, []);

  const copySerie = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `subscribers/copySerie`;
    options.data = JSON.stringify({
      source_ser_id: currentSubscriber.ser_id,
      target_ser_id: selectedSeries,
      move: move,
    });
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenCopy(false);
        getSubscriberSeries();
        getSubscriberSeriesData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSeries = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `series`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        if (JSON.stringify(response) !== "[{}]") setSeries(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getSubscriberSeries();
    getSubscriberSeriesData();
    getSeries();
  }, []);

  const handleChangeSeries = (event) => {
    setSelectedSerie(event.target.value);
  };

  const columns = [
    {
      label: "Serie name",
      name: "ser_name",
      options: {
        filter: true,
      },
    },
    {
      label: "Subscribers count",
      name: "subscribers_count",
      options: {
        filter: true,
        filterOptions: { fullWidth: true },
      },
    },
    {
      label: "Actions",
      name: "ser_id",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          if (subscriberSeriesData[dataIndex].subscribers_count > 0) {
            if (subscriberSeriesData[dataIndex].ser_id != 0) {
              return (
                <div>
                  <IconButton
                    onClick={() => {
                      setCurrentSubscriber(subscriberSeriesData[dataIndex]);
                      setOpenCopy(true);
                      setMove(true);
                    }}
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                    aria-label="prompt"
                  >
                    <Tooltip id="button-report" title="Move">
                      <DriveFileMoveIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setCurrentSubscriber(subscriberSeriesData[dataIndex]);
                      setOpenCopy(true);
                      setMove(false);
                    }}
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                    aria-label="prompt"
                  >
                    <Tooltip id="button-report" title="Copy">
                      <ContentCopyIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              );
            } else {
              return (
                <IconButton
                  onClick={() => {
                    setCurrentSubscriber(subscriberSeriesData[dataIndex]);
                    setOpenCopy(true);
                    setMove(false);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <Tooltip id="button-report" title="Assign">
                    <AssignmentIndIcon />
                  </Tooltip>
                </IconButton>
              );
            }
          }
        },
      },
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "Sorry, no matching records found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
    },
    print: false,
    filter: false,
    downloadCsv: false,
    search: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: false,
    searchAlwaysOpen: false,
    searchPlaceholder: "Search",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Add
        openModal={openAddModal}
        closeModal={() => setOpenAddModal(false)}
        onDone={handleAddGroup}
      />
      <AddSubscriber openModal={openModal} closeModal={handleCloseModal} onDone={handleAddGroup} />
      <Dialog open={openCopy} closeModal={() => setOpenCopy(false)}>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="xs"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          {currentSubscriber.ser_id != 0 ? (
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              {move ? "Move " : "Copy "} {currentSubscriber.ser_name}
            </MDTypography>
          ) : (
            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
              Assign to serie
            </MDTypography>
          )}
        </MDBox>
        <DialogContent>
          <MDTypography variant="h6" fontWeight="medium" mt={1}>
            {currentSubscriber.ser_id != 0
              ? move
                ? "Move to serie"
                : "Copy to serie"
              : "Assign to serie"}
          </MDTypography>
          <Select
            label={"select any serie"}
            fullWidth
            onChange={handleChangeSeries}
            value={selectedSeries}
          >
            {series
              .filter((ser) => ser.ser_id != currentSubscriber.ser_id)
              .map((item) => (
                <MenuItem key={item.ser_id} value={item.ser_id}>
                  {item.ser_name}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setOpenCopy(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          <MDButton onClick={copySerie} disabled={isLoading} variant="gradient" color="success">
            Save
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWebHook}
        onClose={handleCloseWebHook}
        fullScreen
        disableEscapeKeyDown
        style={{ marginLeft: 300 }}
        sx={{
          //You can copy the code below in your theme
          background: "#F4F0F700",
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="xs"
          coloredShadow="primary"
          mx={2}
          mt={-1}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
            Webhook setup for adding subscribers to your list:
          </MDTypography>
        </MDBox>
        <DialogContent>
          <p>
            In order to add subscribers using webhooks, make sure you setup your source to make a
            call to the following URL:
          </p>
          <p>https://lm.{process.env.REACT_APP_MAIN_DOMAIN}/webhooks/AddSubscriber</p>
          <p>Method: GET</p>
          <br />
          <MDTypography>The following variables are required:</MDTypography>
          <table>
            <thead>
              <tr>
                <th align="left" width="200">
                  Name
                </th>
                <th align="left" width="100">
                  Type
                </th>
                <th align="left" width="600">
                  Value
                </th>
                <th align="left">Example</th>
                <th align="left">Default</th>
                <th align="left">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">{localStorage.getItem("plName")}_id</td>
                <td align="left">Integer</td>
                <td align="left">
                  Your {localStorage.getItem("plName")} account unique ID. Your unique ID is:{" "}
                  {localStorage.getItem("userId")}
                </td>
                <td align="left">user_id</td>
                <td align="left"></td>
                <td align="left">Yes</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">email</td>
                <td align="left">String</td>
                <td align="left">The subscriber's email address</td>
                <td align="left">john@doe.com</td>
                <td align="left"></td>
                <td align="left">Yes</td>
              </tr>
            </tbody>
          </table>
          <br />
          <MDTypography>The following variables are optional:</MDTypography>
          <br />
          <table>
            <thead>
              <tr>
                <th align="left" width="200">
                  Name
                </th>
                <th align="left" width="100">
                  Type
                </th>
                <th align="left" width="600">
                  Value
                </th>
                <th align="left">Example</th>
                <th align="left">Default</th>
                <th align="left">Required</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">first</td>
                <td align="left">String</td>
                <td align="left">Subscriber's first name</td>
                <td align="left">John</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">last</td>
                <td align="left">String</td>
                <td align="left">Subscriber's last name</td>
                <td align="left">Doe</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">phone</td>
                <td align="left">String</td>
                <td align="left">Phone number</td>
                <td align="left">+00 123-456-789</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinIP</td>
                <td align="left">String</td>
                <td align="left">IPv4 optin address</td>
                <td align="left">218.15.185.24</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">gender</td>
                <td align="left">String</td>
                <td align="left">Subscriber's gender</td>
                <td align="left">F</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">education</td>
                <td align="left">String</td>
                <td align="left">Education level</td>
                <td align="left">highschool</td>
                <td align="left"></td>
                <td align="left"> No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">employment</td>
                <td align="left">String</td>
                <td align="left">Employment details</td>
                <td>Assistant manager</td>
                <td></td>
                <td>No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">ethnicity</td>
                <td align="left">String</td>
                <td align="left">Subscriber's ethnicity</td>
                <td align="left">African-American</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">children</td>
                <td align="left">String</td>
                <td align="left">Subscriber Children's information</td>
                <td align="left">Yes / No</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">source</td>
                <td align="left">String</td>
                <td align="left">traffic source information</td>
                <td align="left">My funnel</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">city</td>
                <td align="left">String</td>
                <td align="left">City of the subscriber</td>
                <td align="left">New York</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">state</td>
                <td align="left">String</td>
                <td align="left">State of the subscriber</td>
                <td align="left">NY</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">counrty</td>
                <td align="left">String</td>
                <td align="left">Country of the subscriber</td>
                <td align="left">United States</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">zip</td>
                <td align="left">String</td>
                <td align="left">ZIP code</td>
                <td align="left">10001</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">lastEngagementDate</td>
                <td align="left">DateTime</td>
                <td align="left">Last recorded date and time of the last engagement in UTC</td>
                <td align="left">2023-12-2024T00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">lastEngagementDate_mmddyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Last recorded date and time of the last engagement in UTC</td>
                <td align="left">04/24/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">lastEngagementDate_mmddyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Last recorded date and time of the last engagement in UTC</td>
                <td align="left">04-24-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">lastEngagementDate_ddmmyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Last recorded date and time of the last engagement in UTC</td>
                <td align="left">24/04/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">lastEngagementDate_ddmmyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Last recorded date and time of the last engagement in UTC</td>
                <td align="left">24-04-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinDate</td>
                <td align="left">DateTime</td>
                <td align="left">Optin date in UTC</td>
                <td align="left">2023-11-24T12:22:00</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinDate_mmddyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Optin date in UTC</td>
                <td align="left">04/24/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinDate_mmddyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Optin date in UTC</td>
                <td align="left">04-24-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinDate_ddmmyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Optin date in UTC</td>
                <td align="left">24/04/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">optinDate_ddmmyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Optin date in UTC</td>
                <td align="left">24-04-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth</td>
                <td align="left">DateTime</td>
                <td align="left">Date of birth</td>
                <td align="left">2023-11-24</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>

              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth_mmddyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Date of birth</td>
                <td align="left">04/24/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth_mmddyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Date of birth</td>
                <td align="left">04-24-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth_ddmmyy_slashes</td>
                <td align="left">String</td>
                <td align="left">Date of birth</td>
                <td align="left">24/04/2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth_ddmmyy_dashes</td>
                <td align="left">String</td>
                <td align="left">Date of birth</td>
                <td align="left">24-04-2024 00:22:11</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
              <tr style={{ outline: "thin solid" }}>
                <td align="left">dateOfBirth_YYYY</td>
                <td align="left">String</td>
                <td align="left">Year of birth</td>
                <td align="left">2001</td>
                <td align="left"></td>
                <td align="left">No</td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <b>Notes:</b>
            <br />- The fields optinDate, lastEngagementDate and dateOfBirth will have priority over
            their formatted equivalent fields and they should be sent in standard DateTime
            formatting <br />
            - Subscriber's age will be calculated using the year of birth <br />- Valid gender
            values can be f, fem, female, m, male (case insensitive).{" "}
            {localStorage.getItem("plName")} will parse them automatically
          </p>
        </DialogContent>

        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton onClick={() => setOpenWebHook(false)} variant="outlined" color="secondary">
            Close
          </MDButton>
        </DialogActions>
      </Dialog>

      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="xs"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDBox display="flex">
              <MDTypography variant="h2">Subscribers</MDTypography>
              {isLoading ? (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              ) : (
                <IconButton onClick={() => setOpenWebHook(true)} sx={{ fontWeight: "bold" }}>
                  <Tooltip id="button-add" title="Web hook instructions">
                    <LanguageIcon />
                  </Tooltip>
                </IconButton>
              )}
              {importStatus && (
                <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                  We're importing your subscribers. This may take a few minutes. Please wait...
                </MDTypography>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox>
              <MDButton variant="gradient" color="secondary" onClick={handleClick}>
                &nbsp;&nbsp; add/update &nbsp;&nbsp;
                <KeyboardArrowDownIcon />
              </MDButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenAddModal(true);
                  }}
                >
                  <AddIcon />
                  &nbsp; manually
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenModal(true);
                  }}
                >
                  <FileUploadIcon />
                  &nbsp; from csv
                </MenuItem>
              </Menu>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <Table groupAdded={addGroup} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
