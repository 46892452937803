import client from "ApiClient";
import React, { useEffect, useRef, useState } from "react";
import { TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import MDBox from "components/MDBox";
import EditIcon from "@mui/icons-material/Edit";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import TocIcon from "@mui/icons-material/Toc";
import MDTypography from "components/MDTypography";
import ConfirmationAlert from "components/ConfirmationAlert";
import Moment from "moment";
import { IconButton, Tooltip, CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import EmailIntros from "components/emailIntros";
import CustomToolbarSelect from "./customToolbarSelect";
import EditArticle from "./editArticle";
import AdditionalContent from "./additionalContent";

/*eslint-disable */
export default function BlogArticlesTable(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditIntros, setOpenEditIntros] = useState(false);
  const [openEditAdditionalContent, setOpenEditAdditionalContent] = useState(false);
  // const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const [selectedBlaID, setSelectedBlaID] = useState(0);
  const [selectedBlaName, setSelectedBlaName] = useState("");

  const options = {
    method: "DELETE",
    url: "blogArticles",
  };

  let articlesSelected = useRef([]);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [prop, ...otherArgs] = args;
    if (typeof prop === "object" && prop && "isEmpty" in prop) {
      const { isEmpty, ...propsWithoutEmpty } = prop;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  useEffect(() => {
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    getData();
  }, [props.blogId]);

  const getData = async () => {
    setIsLoading(true);

    options.method = "GET";
    options.url = `blogArticles/GetArticles?blog_id=` + props.blogId;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setAcconuntsData(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDelete = async () => {    
    setIsLoading(true);
    options.method = "DELETE";
    options.url = "blogArticles/Delete";
    options.data = JSON.stringify(articlesSelected.current);
    options.headers = {
      "content-type": "application/json",
    };
    // console.log(options);
    await client
      .request(options)
      .then(() => {
        setIsLoading(false);
        articlesSelected.current = [];
        setOpenAlert(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  const columns = [
    {
      label: "ID",
      name: "bla_id",
      options: {
        filter: true,
      },
    },
    {
      label: "Name",
      name: "bla_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          return acconuntsData[dataIndex].bla_destinationURL != "" ? (
            <div>
              <Tooltip id="button-report" title="Open article">
                <a href={acconuntsData[dataIndex].bla_destinationURL} target="_blank">
                  {acconuntsData[dataIndex].bla_name}
                </a>
              </Tooltip>
            </div>
          ) : (
            <div>{value}</div>
          );
        },
      },
    },
    {
      label: "Status",
      name: "bla_status",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          return acconuntsData[dataIndex].bla_status == 0 ? <div>Active</div> : <div>Inactive</div>;
        },
      },
    },
    {
      label: "Creation date",
      name: "bla_creationDate",
      options: {
        filter: true,
        customBodyRender: (value) => Moment(value).format("L"),
      },
    },
    {
      label: "Actions...",
      name: "bla_id",
      options: {
        filter: false,
        filterOptions: { fullWidth: true },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Tooltip id="button-report" title="Edit article">
                <IconButton
                  onClick={() => {
                    setSelectedBlaID(acconuntsData[dataIndex].bla_id);
                    setOpenEdit(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip id="button-report" title="Edit intro paragraph">
                <IconButton
                  onClick={() => {
                    setSelectedBlaName(acconuntsData[dataIndex].bla_name);
                    setSelectedBlaID(acconuntsData[dataIndex].bla_id);
                    setOpenEditIntros(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <ViewHeadlineIcon />
                </IconButton>
              </Tooltip>
              <Tooltip id="button-report" title="View additional content">
                <IconButton
                  onClick={() => {
                    setSelectedBlaName(acconuntsData[dataIndex].bla_name);
                    setSelectedBlaID(acconuntsData[dataIndex].bla_id);
                    setOpenEditAdditionalContent(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <TocIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  /*
  <Tooltip id="button-report" title="Edit additional content">
                <IconButton
                  onClick={() => {
                    setSelectedBlaName(acconuntsData[dataIndex].bla_name);
                    setSelectedBlaID(acconuntsData[dataIndex].bla_id);
                    setOpenEditAdditionalContent(true);
                  }}
                  sx={{ fontWeight: "bold" }}
                  color="primary"
                  aria-label="prompt"
                >
                  <TocIcon />
                </IconButton>
              </Tooltip>
              
 
*/

  const customToolbar = () => (
    <CustomToolbarSelect onEdit={handleEdit} onDelete={() => setOpenAlert(true)} />
  );

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any blog article",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "article(s) selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogArticles.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      articlesSelected.current = [];
      allRowsSelected.map((row) => {
        articlesSelected.current.push(acconuntsData[row.dataIndex]);
      });
     // console.log("articlesSelected", articlesSelected.current);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onYes={() => handleDelete()}
        onClose={() => setOpenAlert(false)}
        yesText="Delete"
        title="Are you sure you want to delete this blog? The catalog associated with it and all its articles will be deleted."
      />
      <EditArticle
        openModal={openEdit}
        closeModal={() => {
          setOpenEdit(false);
          getData();
        }}
        blaId={selectedBlaID}
      />
      <EmailIntros
        openModal={openEditIntros}
        closeModal={() => setOpenEditIntros(false)}
        blaId={selectedBlaID}
        article={selectedBlaName}
      />
      <AdditionalContent
        openModal={openEditAdditionalContent}
        closeModal={() => setOpenEditAdditionalContent(false)}
        blaId={selectedBlaID}
        article={selectedBlaName}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="xs"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Articles for blog {props.blogName}
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={2} style={{ width: "100%", textAlign: "right" }}>
            <MDBox justifyContent="right">
              <Tooltip title="Create a new blog article">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  Create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
